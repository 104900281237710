import React from "react";
import Logo from "../../assets/image/blacklogo.png";

function Home() {
  return (
    <div className="bg-[#fbf8ef] h-screen">
      <div className="py-6 px-16 max-sm:px-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img src={Logo} alt="logo" className="w-12" />
          </div>
          <div className="flex items-center  max-[720px]:hidden">
            {" "}
            <div className="text-black/90 mx-6">About</div>
            <div className="text-black/90 mx-6">blogs</div>
            <div className="text-black/90 mx-6">Contact us</div>
          </div>
          <div>
            <div className="py-3 font-semibold px-7 rounded-full border-[2px] border-black text-black">
              Get Ruffelo
            </div>
          </div>
        </div>
      </div>
      <div className="h-[500px] w-full flex flex-col items-center justify-center max-[720px]:px-16 max-[720px]:h-[550px] max-sm:px-6">
        <div className="text-8xl font-light w-8/12 text-center max-[720px]:text-6xl max-[1140px]:w-full">
          Welcome to <span className="font-bold">Ruffelo</span>
        </div>
        <div className="text-xl w-7/12 mt-3 max-[720px]:text-xl max-[720px]:w-full max-sm:text-center">
          Your financial partner on the go. Download free apps, complete tasks,
          and turn your phone into a cash machine!
        </div>
        <div className="mt-14 flex items-center">
          <div className="py-3.5 px-8 bg-[black] mx-2 rounded-full text-white max-sm:px-6 max-sm:text-sm">
            Download now
          </div>
          <div className="py-3.5 px-8 border-[2px] mx-2 border-[black] rounded-full text-black text-center">
            Contact us
          </div>
        </div>
      </div>
      <div className="px-16 flex items-center font-semibold">
        <div className="mx-5">privacy</div>
        <div className="mx-5">terms</div>
        <div className="mx-5">faqs</div>
      </div>
    </div>
  );
}

export default Home;
